export const calcResultMock = {
  payment_from_rub: 204620.83333333334,
  acquisition_cost_rub: 24254040,
  advance_payment: 1752520,
  advance_payment_rub: '1752520',
  auth: false,
  id_request: 3835078,
  leasingproduct: 'Универсальный лизинг (Раннее бронирование 2022)',
  payments: [
    {
      id: null,
      isadvance: false,
      ndsValue: '43 317,00',
      payActual: '259 902,00',
      payDate: '23.02.2023',
      payNumber: '1',
      payValue: '1',
      payWithRate: '222',
      remuneration: '12',
      title: null,
    },
  ],
  riseInPricePrc: '383952',
  riseInPricePrcY: '383952',
  subsidy: null,
  success: true,
  order_summ: '121 1213 232, 00',
  each_payment: 33.333,
  taxBenefit: '2 698 157',
  taxEconomy: '3 071 077',
  taxRefund: '3 071 077',
};

export const calcSettingsMock = {
  associations: [
    {
      title: 'АККОР',
      code: '',
    },
  ],
  max_term_years: '7',
  min_term_years: '1',
  min_advance_pay: '0',
  repayment_types: [
    {
      title: 'Аннуитет',
      code: '1',
    },
  ],
  regions: [
    {
      title: 'Адыгея Респ',
      code: '0100000000000',
    },
  ],
};

export const calcCompetitorsMock = [
  {
    id_product: '145777',
    supplier: {
      id: '6',
      title: 'КЗ РОСТСЕЛЬМАШ',
    },

    pricelevels: [
      {
        id: '31017971',
        value: '13796160.00',
        money: 'руб',
        guid: '9630cdfa-cd7b-4673-99e9-4e3b623aff22',
      },
    ],
  },
];
