export const aboutCompanyUnionList = [
  {id: 1, title: 'АККОР', code: ''},
  {id: 2, title: 'АССОЦИАЦИЯ ПИТОМНИКОВОДОВ И САДОВОДОВ СТАВРОПОЛЬСКОГО КРАЯ', code: ''},
  {
    id: 3,
    title: 'АССОЦИАЦИЯ ПРОИЗВОДИТЕЛЕЙ ПЛОДОВ, ЯГОД И ПОСАДОЧНОГО МАТЕРИАЛА',
    code: '',
  },
  {id: 4, title: 'КАРТОФЕЛЬНЫЙ СОЮЗ НССИС', code: ''},
  {id: 5, title: 'НССИС', code: ''},
  {id: 6, title: 'ПЛОДООВОЩНОЙ СОЮЗ', code: ''},
  {id: 7, title: 'САДОВОДЫ КУБАНИ', code: ''},
  {id: 8, title: 'СОЮЗ ВИНОГРАДАРЕЙ И ВИНОДЕЛОВ РОССИИ', code: ''},
  {id: 9, title: 'ТЕПЛИЦЫ РОССИИ АССОЦИАЦИЯ', code: ''},
];
