import {getBorderConditions} from '@api/catalog';
import {getCalcResult} from '@api/main';
import {
  CalcGetPaymentOptions,
  CalcValues,
  KeysOf,
  SelectOptions,
} from '@api/types/Catalog';
import {CalcSettings, Competitor} from '@api/types/CatalogProduct';
import MainCalcTooltipList from '@components/atoms/MainCalcTooltipList';
import ProductCardCalcAutoSave from '@components/atoms/ProductCardCalcAutoSave';
import {useWindowDimensions} from '@hooks';
import {aboutCompanyUnionList} from '@mockData/aboutCompanyUnionList';
import {theme} from '@styles/theme';
import {CalcResultContext} from '@utils/contexts';
import {divideNumber, yearsToStr} from '@utils/formatters';
import {Formik} from 'formik';
import {debounce} from 'lodash';
import Image from 'next/legacy/image';
import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Tooltip} from '../Tooltip';
import {
  CheckboxContainer,
  CheckboxImage,
  CheckboxWrapper,
  FormWrapper,
  PriceRangeInputMinMax,
  RangeInputWrapper,
  SelectTypes,
  StyledCheckbox,
  StyledRangeInput,
  StyledSelect,
  TooltipWrapper,
} from './MainPageCalcForm.styles';
import {useRouter} from 'next/router';
import {trackEvent} from '@utils/analytics';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';

interface MainPageCalcFormProps {
  calcSettings: CalcSettings;
  relevantCompetitors: Competitor[];
  testID?: string;
  testSubmit?: (values: any) => any;
  setCalcResultLoading?: (values: boolean) => void;
  categories: Array<{title: string; value: string}> | any;
  activeTab: string;
  background?: string;
}

const minPrice = 1000000;
const maxPrice = 50000000;

const MainPageCalcForm: FC<MainPageCalcFormProps> = ({
  calcSettings,
  setCalcResultLoading,
  categories,
  activeTab,
  background = ''
}) => {
  const [defaultValuesState, setDefaultValuesState] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<SelectOptions[]>([
    {title: 'Не выбрано', value: '0'},
  ]);
  const [advPayMinAndTermMax, setAdvPayMinAndTermMax] = useState({
    advPayMin: +calcSettings.min_advance_pay,
    termMax: +calcSettings.max_term_years,
  });
  const [initialValues, setInitialValues] = useState({
    category: '0',
    repayment_type: +paymentOptions[0].value,
    advance_payment:
      advPayMinAndTermMax.advPayMin === 0
        ? +calcSettings.min_advance_pay
        : advPayMinAndTermMax.advPayMin,
    term_of_leasing:
      advPayMinAndTermMax.termMax === 0
        ? +calcSettings.max_term_years
        : advPayMinAndTermMax.termMax,
    price: 15000000,
    quantity: 1,
    isassociation: false,
    isshtp: true,
  });

  const {width} = useWindowDimensions();

  const formikRef: any = useRef(null);

  const [calcResult, setCalcResult] = useContext(CalcResultContext);

  const router = useRouter();

  const [changedByUser, setChangedByUser] = useState(false);
  const prevActiveTab = useRef(activeTab);

  const onSubmitCalc = useCallback(
    debounce(async (values: CalcValues) => {
      for await (const field of Object.keys(initialValues)) {
        document.getElementById(field)?.blur();
      }
      setCalcResultLoading && setCalcResultLoading(true);
      const result = await getCalcResult(values);
      setCalcResult && setCalcResult(result);
      setCalcResultLoading && setCalcResultLoading(false);
      if (router.route === '/' && changedByUser) {
        trackEvent(
          AnalyticEventCategories.MainPage,
          AnalyticEventNames.MainPageCalcNewResult,
        );
      }
    }, 250),
    [changedByUser],
  );

  useEffect(() => {
    if (formikRef.current.values.term_of_leasing > advPayMinAndTermMax.termMax) {
      formikRef.current.setFieldValue('term_of_leasing', advPayMinAndTermMax.termMax);
    }
    if (formikRef.current.values.advance_payment < advPayMinAndTermMax.advPayMin) {
      formikRef.current.setFieldValue('advance_payment', advPayMinAndTermMax.advPayMin);
    }
  }, [advPayMinAndTermMax]);

  useEffect(() => {
    if (prevActiveTab.current !== activeTab && !changedByUser) {
      setChangedByUser(true);
      prevActiveTab.current = activeTab;
    }
    
    switch (activeTab) {
      case 'optimal':
        setInitialValues({
          ...initialValues,
          ...formikRef.current.values,
          advance_payment: 10,
          term_of_leasing: 3,
        });
        break;
      case 'minPay':
        setInitialValues({
          ...initialValues,
          ...formikRef.current.values,
          advance_payment: 49,
          term_of_leasing: advPayMinAndTermMax.termMax,
        });
        break;
      case 'noOverPay':
        setInitialValues({
          ...initialValues,
          ...formikRef.current.values,
          advance_payment: 49,
          term_of_leasing: 1,
        });
        break;
      case 'minAdvPay':
        setInitialValues({
          ...initialValues,
          ...formikRef.current.values,
          advance_payment: advPayMinAndTermMax.advPayMin,
          term_of_leasing: 1,
        });
        break;
    }
  }, [activeTab]);

  async function onTechChange(value: any) {
    const fieldKeys: KeysOf<CalcGetPaymentOptions>[] = [
      'advancePay',
      'orderPeriodYears',
      'isAnnuitet',
      'isRegress',
      'isSeasonality',
    ];

    const res = await getBorderConditions(value);

    const newOptions: SelectOptions[] = [];

    setAdvPayMinAndTermMax({
      advPayMin: res.advancePay ?? +calcSettings.min_advance_pay,
      termMax: res.orderPeriodYears ?? +calcSettings.max_term_years,
    });

    if (value === '0') {
      setAdvPayMinAndTermMax({
        advPayMin: +calcSettings.min_advance_pay,
        termMax: +calcSettings.max_term_years,
      });
    }

    for (const i of fieldKeys) {
      if (res[i] === '1') {
        if (i === 'isAnnuitet') {
          newOptions.push({title: 'Аннуитет', value: '1' as string});
        }
        if (i === 'isRegress') {
          newOptions.push({title: 'Регресс', value: '2' as string});
        }
        if (i === 'isSeasonality') {
          newOptions.push({title: 'Сезонный', value: '3' as string});
        }
      }
    }

    if (newOptions.length === 0) {
      setPaymentOptions([{title: 'Не выбрано', value: '0'}]);
      return;
    } else {
      setPaymentOptions([...newOptions]);
    }
    formikRef.current.setFieldValue('category', value);
  }

  useEffect(() => {
    formikRef.current.setFieldValue('repayment_type', +paymentOptions[0].value);
  }, [paymentOptions]);

  useEffect(() => {
    if (categories.length && !defaultValuesState) {
      onTechChange(categories[0].value);
      setDefaultValuesState(true);
    }
  }, [categories]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmitCalc}
      enableReinitialize>
      {({handleSubmit, handleChange, values, setFieldValue}) => (
        <form
          onSubmit={handleSubmit}
          style={{position: 'relative'}}
          onChange={() => {
            !changedByUser && setChangedByUser(true);
          }}>
          <ProductCardCalcAutoSave />
          <FormWrapper>
            <SelectTypes>
              <StyledSelect
                onChange={value => {
                  onTechChange(value);
                  setFieldValue('category', String(value));
                  !changedByUser && setChangedByUser(true);
                }}
                value={String(values.category)}
                id="category"
                label="Тип техники"
                options={categories}
                labelBG={background}
                //options={[{title: 'Не выбрано', value: '0'}, ...categories]}
              />
              <StyledSelect
                onChange={value => {
                  setFieldValue('repayment_type', value);
                  !changedByUser && setChangedByUser(true);
                }}
                value={String(values.repayment_type)}
                id="repayment_type"
                label="График платежей"
                options={paymentOptions}
                inputDisabled={values.category === '0'}
                labelBG={background}
              />
            </SelectTypes>
            <RangeInputWrapper>
              <StyledRangeInput
                id="price"
                label="Стоимость техники"
                min={minPrice}
                max={maxPrice}
                onChange={value => setFieldValue('price', value)}
                value={values.price}
                suffix={' ₽'}
                labelBG={background}
              />
              <PriceRangeInputMinMax>
                <p>{divideNumber(minPrice)} ₽</p>
                <p>{divideNumber(maxPrice)} ₽</p>
              </PriceRangeInputMinMax>
            </RangeInputWrapper>
            <RangeInputWrapper>
              <StyledRangeInput
                id="advance_payment"
                label="Первоначальный взнос"
                min={
                  advPayMinAndTermMax.advPayMin === 0
                    ? +calcSettings.min_advance_pay
                    : advPayMinAndTermMax.advPayMin
                }
                max={49}
                onChange={value => setFieldValue('advance_payment', value)}
                value={values.advance_payment}
                suffix={'%'}
                labelBG={background}
              />
              <PriceRangeInputMinMax>
                <p>
                  {advPayMinAndTermMax.advPayMin === 0
                    ? +calcSettings.min_advance_pay
                    : advPayMinAndTermMax.advPayMin}
                  %
                </p>
                <p>49%</p>
              </PriceRangeInputMinMax>
            </RangeInputWrapper>

            <RangeInputWrapper>
              <StyledRangeInput
                id="term_of_leasing"
                label="Срок договора"
                min={1}
                max={
                  advPayMinAndTermMax.termMax === 0
                    ? +calcSettings.max_term_years
                    : advPayMinAndTermMax.termMax
                }
                onChange={value => setFieldValue('term_of_leasing', value)}
                value={values.term_of_leasing}
                suffix={` ${yearsToStr(values.term_of_leasing)}`}
                labelBG={background}
              />
              <PriceRangeInputMinMax>
                <p>1 год</p>
                <p>
                  {`${
                    advPayMinAndTermMax.termMax === 0
                      ? +calcSettings.max_term_years
                      : advPayMinAndTermMax.termMax
                  }
                  ${' '}
                  ${yearsToStr(
                    advPayMinAndTermMax.termMax === 0
                      ? +calcSettings.max_term_years
                      : advPayMinAndTermMax.termMax,
                  )}`}
                </p>
              </PriceRangeInputMinMax>
            </RangeInputWrapper>

            <CheckboxContainer>
              <div>
                <StyledCheckbox
                  id="isshtp"
                  onChange={handleChange}
                  label="Я являюсь СХТП"
                  checked={Boolean(values.isshtp)}
                  checkboxSquareHeight={24}
                  checkboxSquareWidth={24}
                  marginBetweenLabel={10}
                  labelFontSize={width && width <= 500 ? '12' : '14'}
                  checkMarkPosTop={10}
                  checkMarkPosRight={20}
                  squareColor={theme.colors.lightGreen}
                />
              </div>
              <CheckboxWrapper>
                <StyledCheckbox
                  id="isassociation"
                  onChange={handleChange}
                  checked={Boolean(values.isassociation)}
                  label="Являюсь членом отраслевого союза/ассоциации"
                  checkboxSquareHeight={24}
                  checkboxSquareWidth={24}
                  marginBetweenLabel={10}
                  labelFontSize={width && width <= 500 ? '12' : '14'}
                  checkMarkPosTop={10}
                  checkMarkPosRight={20}
                  squareColor={theme.colors.lightGreen}
                />
                <TooltipWrapper>
                  <Tooltip
                    overlay={<MainCalcTooltipList items={aboutCompanyUnionList} />}>
                    <CheckboxImage tabIndex={-1}>
                      <Image
                        src={'/assets/icons/informationIcon.svg'}
                        layout="intrinsic"
                        width={100}
                        height={100}
                        alt="information icon"
                      />
                    </CheckboxImage>
                  </Tooltip>
                </TooltipWrapper>
              </CheckboxWrapper>
            </CheckboxContainer>
          </FormWrapper>
        </form>
      )}
    </Formik>
  );
};

export default MainPageCalcForm;
