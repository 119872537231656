import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import {
  BlogBlockItemWrapper,
  BlogItemImage,
  BlogItemInfo,
  GoToMore,
  GoToMoreWrapper,
  Title,
} from './BlogBlockItem.styles';

interface BlogBlockItemProps {
  img: string;
  title: string;
  url: string;
  style?: Record<string, string>;
  onClick?: () => void;
}

const BlogBlockItem: FC<BlogBlockItemProps> = ({
  title, 
  url, 
  img, 
  style, 
  onClick
}) => {
  return (
    <BlogBlockItemWrapper style={style}>
      <Link 
        onClick={onClick} 
        href={url} 
        passHref 
        target="_blank" 
        rel="noopener">
        <BlogItemImage>
          <Image
            src={img}
            layout="fill"
            objectFit="cover"
            height={208}
            alt={`Изображение статьи блога`}
            title={title}
          />
        </BlogItemImage>
        <BlogItemInfo>
          <Title>{title}</Title>
        </BlogItemInfo>
      </Link>
      <GoToMoreWrapper>
        <GoToMore href={url} target="_blank" rel="noopener">
          Читать
        </GoToMore>
      </GoToMoreWrapper>
    </BlogBlockItemWrapper>
  );
};

export default BlogBlockItem;
