import {theme} from '@styles/theme';
import styled from 'styled-components';

export const GoToMore = styled.a`
  transition: color 0.5s;
  color: ${({theme}) => theme.colors.brandGreenV2};
`;

export const GoToMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 40;
  margin-top: 10px;
  a {
    font-size: 14px;
    color: ${({theme}) => theme.colors.brandGreenV2};
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: url('/assets/icons/rightArrowIcon.svg');
      ${theme.filters.brandGreenV2}
      margin-left: 8px;
      padding-top: 4px;
    }
  }
  @media (max-width: 440px) {
    justify-content: flex-start;
  }
`;

export const BlogBlockItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  min-height: 100%;
  position: relative;
  cursor: pointer;
  &:hover ${GoToMoreWrapper} a {
    color: ${({theme}) => theme.colors.lightGreen};
    &:after {
      content: url('/assets/icons/rightArrowIcon.svg');
      ${theme.filters.lightGreen}
    }
  }
  @media (max-width: 720px) {
    flex-wrap: wrap;
    flex: 1 1 45%;
  }
  @media (max-width: 440px) {
    flex: 1 1 100%;
  }
`;

export const BlogItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlogItemImage = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  height: 208px;
  max-height: 208px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 21px;
  color: ${theme.colors.darkText};
  width: 100%;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 440px) {
    width: 95%;
  }
`;
