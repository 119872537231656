import Checkbox from '@components/atoms/Checkbox';
import RangeInput from '@components/atoms/RangeWrapper';
import Select from '@components/atoms/Select';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1 1 50%;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const SelectTypes = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 50%;
  gap: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const RangeInputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

export const PriceRangeInputMinMax = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: -45%;
  width: 100%;

  p {
    font-size: 10px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
  }
  @media (max-width: 800px) {
    width: 99%;
    margin: 4px 1.5px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    align-self: center;
    margin-top: 30px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: start;
    align-self: start;
    gap: 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxImage = styled.div`
  max-width: 14px;
  min-width: 14px;
  height: 14px;
  margin-left: 9px;
  margin-bottom: 2px;
  cursor: pointer;
  & img {
    filter: grayscale(100%);
  }
  & img:hover {
    filter: grayscale(0%);
  }
`;

export const StyledSelect = styled(Select)`
  display: flex;
  max-height: 50px;
  color: ${theme.colors.darkText};
  height: 50px;
  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    background-color: ${props => props.labelBG ? props.labelBG : theme.colors.lightBG};
    color: ${theme.colors.gray};
  }
`;

export const StyledRangeInput = styled(RangeInput)`
  display: flex;
  position: relative;
  min-height: 50px;
  max-height: 50px;
  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    background-color:${props => props.labelBG ? props.labelBG : theme.colors.lightBG};
    color: ${theme.colors.gray};
  }
  & > input[type='range'] {
    position: absolute;
    background-color: ${theme.colors.lightGrayV2};
    height: 1px;
    top: initial;
    bottom: -1px;
    pointer-events: all;
    transition: all 0.5s;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  label:nth-child(2n) {
    border: 1px solid ${theme.colors.brandGreenV2};
  }
`;

export const TooltipWrapper = styled.div`
  flex-shrink: 0;
`;
